import { App as CapacitorApp } from '@capacitor/app';
import type { URLOpenListenerEvent } from '@capacitor/app';
import { createObjectFromString, getRedirectRoute } from '@/utils/helpers';
import { handleUserLocale } from '@/i18n';
import { useRouter } from 'vue-router';

export const useDeepLinks = () => {
	const router = useRouter();

	const listenForDeeplink = () => {
		CapacitorApp.addListener(
			'appUrlOpen',
			async function (event: URLOpenListenerEvent) {
				const urlArray = event.url.split('?');
				const path = getRedirectRoute(event.url);
				let queryObj;
				if (urlArray.length !== 0) {
					queryObj = createObjectFromString(event.url.split('?')[1]);

					await handleUserLocale(queryObj.locale);

					delete queryObj.locale;

					const newPath = {
						path: `/${path}`,
						query: {
							...queryObj,
						},
					};

					await router.push(newPath);
				} else {
					return;
				}
			}
		);
	};

	return {
		listenForDeeplink,
	};
};
