import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import type { Modal } from '@/types';

export const useModalStorage = (): {
	pushModal: (props: Modal) => Promise<void>;
	shiftModal: () => Promise<Modal>;
	activeModal: Ref<Modal>;
	storage: Ref<Modal[] | []>;
	closeAllModals: () => void;
} => {
	const storage = useStorage<Modal[] | []>('modal-storage', []);
	const activeModal = useStorage('active-modal', {});

	const pushModal = async (props: Modal): Promise<void> => {
		const modalToPush = JSON.stringify(props);

		const hasStorageTheSameModal = storage.value.some(
			(item) => JSON.stringify(item) === modalToPush
		);

		const isActiveModalTheSame =
			JSON.stringify(activeModal.value) === modalToPush;

		if (!hasStorageTheSameModal && !isActiveModalTheSame) {
			storage.value = [...storage.value, props];
		}
	};

	const shiftModal = async (): Promise<Modal | {}> => {
		if (storage.value.length) {
			activeModal.value = await storage.value[0];
			storage.value = await storage.value.slice(1);
		} else {
			if (Object.keys(activeModal.value).length) {
				activeModal.value = {};
			}
		}

		return activeModal;
	};

	const closeAllModals = () => {
		activeModal.value = {};
		storage.value = [];
	};

	return {
		pushModal,
		shiftModal,
		activeModal,
		storage,
		closeAllModals,
	};
};
