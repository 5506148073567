import API from '@/api';
import type { Notification } from '@/types';

export const getNotifications = async (): Promise<Notification[]> => {
	const { data } = await API.get<Notification[]>('/api/notifications');
	return data;
};

export const setNotificationAsRead = async (id: string): Promise<void> => {
	await API.put(`/api/notifications/${id}/mark-as-read`);
};
