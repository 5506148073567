import { computed } from 'vue';
import {
	SURVEYS,
	LEADERBOARD,
	ACCOUNT,
	MORE,
	NEW,
	HELP,
	REFER_LINK_TITLE,
	REDEEM_LINK_TITLE,
	INVITE_FRIEND,
} from '@/locales/constants';
import { useI18n } from 'vue-i18n';
import type { NavItem } from '@/types';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	PiClipboardText,
	PiDotsThreeCircle,
	PiTrophy,
	PiUserCircle,
	PiCoins,
	PiGift,
	PiLifebuoyDuotone,
	PiUserCircleDuotone,
	PiGameControllerDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import { USER_ROUTE_NAME } from '@/constants/routes';

export const useNavItems = () => {
	const { isOfferwallsAvailable, isPsOffersEnabled } =
		storeToRefs(useUserStore());
	const { t } = useI18n();

	// We need it for conditional rendering of the old Offerwalls, can be removed when only PS Offers will stay in the app
	const moreItems = computed<Array<NavItem>>(() => [
		{
			icon: PiUserCircle,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			isActive: true,
			id: USER_ROUTE_NAME.PROFILE,
			placed: ['mobile'],
		},
		{
			icon: PiLifebuoyDuotone,
			label: t(HELP),
			to: USER_ROUTE_NAME.SUPPORT,
			isActive: true,
			id: USER_ROUTE_NAME.SUPPORT,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiGift,
			label: t(REFER_LINK_TITLE),
			subTitle: t(INVITE_FRIEND),
			to: USER_ROUTE_NAME.REFERRALS,
			isActive: true,
			id: USER_ROUTE_NAME.REFERRALS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiUserCircleDuotone,
			label: t(REDEEM_LINK_TITLE),
			isActive: true,
			id: 'redeem',
			placed: ['desktop', 'mobile'],
		},
	]);

	const items = computed<Array<NavItem>>(() => [
		{
			icon: PiClipboardText,
			label: t(SURVEYS),
			to: USER_ROUTE_NAME.SURVEYS,
			isActive: true,
			id: USER_ROUTE_NAME.SURVEYS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiGameControllerDuotone,
			label: 'Offers',
			to: USER_ROUTE_NAME.OFFERS,
			isActive: Boolean(isPsOffersEnabled.value),
			pin: NEW,
			id: USER_ROUTE_NAME.OFFERS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiCoins,
			label: isPsOffersEnabled.value ? 'Offerwalls' : 'Offers',
			to: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
			isActive: Boolean(isOfferwallsAvailable.value),
			...(!isPsOffersEnabled.value && { pin: NEW }),
			id: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
			placed: isPsOffersEnabled.value ? ['desktop'] : ['desktop', 'mobile'],
		},
		{
			icon: PiTrophy,
			label: t(LEADERBOARD),
			to: USER_ROUTE_NAME.LEADERBOARD,
			isActive: true,
			id: USER_ROUTE_NAME.LEADERBOARD,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiUserCircle,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			isActive: true,
			id: USER_ROUTE_NAME.PROFILE,
			placed: ['desktop'],
		},
		{
			icon: PiDotsThreeCircle,
			label: t(MORE),
			isActive: true,
			id: 'more',
			placed: ['desktop', 'mobile'],
			children: isPsOffersEnabled.value
				? [
						{
							icon: PiCoins,
							label: 'Offerwalls',
							to: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
							isActive: Boolean(isOfferwallsAvailable.value),
							id: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
							placed: ['mobile'],
						},
						...moreItems.value,
					]
				: moreItems.value,
		},
	]);

	const filterItems = (
		items: NavItem[],
		type: 'mobile' | 'desktop'
	): NavItem[] => {
		return items
			.filter((item) => item.placed.includes(type) && item.isActive)
			.map((item) => ({
				...item,
				children: item.children ? filterItems(item.children, type) : undefined,
			}));
	};

	const desktopItems = computed(() => filterItems(items.value, 'desktop'));
	const mobileItems = computed(() => filterItems(items.value, 'mobile'));

	return {
		desktopItems,
		mobileItems,
		items,
	};
};
