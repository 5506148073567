<script setup lang="ts">
import {
	OKAY,
	WELL_DONE_V2,
	OFFERWALLS_WELL_DONE_MODAL_TITLE,
	OFFERWALLS_WELL_DONE_MODAL_TEASER,
} from '@/locales/constants';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContentV2.vue';
import congratulations from '@/assets/icons/congratulations.svg';
import { localizePoints } from '@/utils/helpers';
import type { OfferwallTransactionDelayed } from '@/types';

defineProps<{
	data: OfferwallTransactionDelayed;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(WELL_DONE_V2) }}</template>
		<template #icon>
			<img :src="congratulations" alt="congratulations" />
		</template>
		<template #title>{{
			$t(OFFERWALLS_WELL_DONE_MODAL_TITLE, {
				name: data.offer_name,
				points: localizePoints(data.coin_value),
			})
		}}</template>
		<template #message>{{
			$t(OFFERWALLS_WELL_DONE_MODAL_TEASER, { days: data.credit_delay })
		}}</template>
		<template #actions>
			<PrimeButton
				type="fill"
				rounded="rounded"
				full-width
				:label="$t(OKAY)"
				@click="handleCloseModal"
			/>
		</template>
	</ModalCommonContent>
</template>
