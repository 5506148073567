<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { SELECT_COUNTRY_LANGUAGE_MODAL } from '@/constants/modals';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { PiGlobe } from '@primeinsightsgroupllc/prime-icons';

const { pushModal } = useModalStorage();
const authStore = useAuthStore();
const { localizationText } = storeToRefs(authStore);

const openLangModal = async (): Promise<void> => {
	await pushModal({
		name: SELECT_COUNTRY_LANGUAGE_MODAL,
	});
};
</script>

<template>
	<button class="language-switcher" @click="openLangModal">
		<PiGlobe class="icon" color="inherit" />
		{{ localizationText }}
	</button>
</template>

<style lang="scss" scoped>
.language-switcher {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.2;
	padding: 0.3125rem 0.625rem;
	color: var(--text-default);
	border: 1px solid var(--grey-200);
	background: var(--grey-200);
	transition: background 0.2s;
	border-radius: 3.125rem;

	&:hover,
	&:focus {
		background: transparent;
	}

	.icon {
		margin-right: 0.5rem;
	}
}
</style>
