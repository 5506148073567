import { ref, watch } from 'vue';
import type { Ref } from 'vue';
import { cidStorage } from '@/utils/local-storage';
import { useMobileApp } from '@/utils/composables/useMobileApp';

export const useAppRerender = (): {
	appKey: Ref<number>;
} => {
	const appKey = ref(0);
	const { isMobileApp } = useMobileApp();

	watch(cidStorage, (newValue) => {
		if (newValue && isMobileApp) {
			appKey.value = appKey.value + 1;
		}
	});

	return {
		appKey,
	};
};
