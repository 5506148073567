<script setup lang="ts">
import { ref, watch } from 'vue';
import {
	getCountryLanguages,
	getCountryName,
	getLanguageNative,
} from '@/utils/helpers';
import { SELECT_LANGUAGE } from '@/locales/constants';
import {
	PrimeAccordion,
	PrimeAccordionTab,
	PrimeRadio,
	PrimeText,
	PrimeCountryFlag,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const props = defineProps<{
	country: string;
	selectedLanguage: string;
	isSelected?: boolean;
}>();

defineEmits<{
	(e: 'select-localization', localization: string): void;
}>();

const language = ref(props.selectedLanguage || '');

watch(
	() => props.selectedLanguage,
	(newValue) => {
		language.value = newValue;
	}
);
</script>

<template>
	<PrimeAccordion
		class="country"
		:class="{ selected: isSelected }"
		:active-index="isSelected ? 0 : -1"
	>
		<PrimeAccordionTab>
			<template #title>
				<div class="country-name">
					<PrimeCountryFlag :country-code="country" />
					<PrimeText tag="h4" size="sm" weight="500">
						{{ getCountryName(country) }}
					</PrimeText>
				</div>
			</template>
			<template #content>
				<PrimeText class="country-label" tag="div" size="sm" color="grey-700">
					{{ $t(SELECT_LANGUAGE) }}
				</PrimeText>
				<div
					v-for="localization in getCountryLanguages(country)"
					:key="localization"
					class="country-languages"
				>
					<PrimeRadio
						v-model="language"
						:checked="localization === selectedLanguage"
						:val="localization"
						:name="localization"
						:label="getLanguageNative(localization)"
						@input="$emit('select-localization', localization)"
					/>
				</div>
			</template>
		</PrimeAccordionTab>
	</PrimeAccordion>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.country {
	margin-bottom: 0.75rem;

	@include breakpoint(mobileonly) {
		margin-bottom: 1.125rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.selected {
		:deep(.p-accordion-tab) {
			border-color: transparent;
			background-color: var(--base-primary-03);
		}

		:deep(.p-accordion-button) {
			pointer-events: none;

			& + div {
				max-height: unset;
			}
		}

		:deep(.p-accordion-icon) {
			display: none;
		}
	}
}

.country-label {
	text-align: start;
}

.country-name {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.country-label {
	margin-bottom: 0.5rem;
}

.country-languages {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	line-height: 1;
	margin-bottom: 0.5rem;
}
</style>
