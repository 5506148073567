<script setup lang="ts">
import { markRaw, ref, computed } from 'vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import rocket from '@/assets/icons/rocket.svg';
import {
	PiHandCoinsDuotone,
	PiHcRocket,
	PiNavbar,
	PiPalette,
	PiClose,
} from '@primeinsightsgroupllc/prime-icons';
import {
	ANNOUCEMENT_V2_HEAD,
	ANNOUCEMENT_V2_TITLE,
	ANNOUCEMENT_V2_SUBTITLE,
	ANNOUCEMENT_V2_BTN,
	ANNOUCEMENT_V2_LIST_1_TITLE,
	ANNOUCEMENT_V2_LIST_1_TEASER,
	ANNOUCEMENT_V2_LIST_2_TITLE,
	ANNOUCEMENT_V2_LIST_2_TEASER,
	ANNOUCEMENT_V2_LIST_3_TITLE,
	ANNOUCEMENT_V2_LIST_3_TEASER,
	ANNOUCEMENT_V2_LIST_4_TITLE,
	ANNOUCEMENT_V2_LIST_4_TEASER,
} from '@/locales/constants';
import { MessageChannels } from '@/enums';

type ModalOptions = {
	channel: string;
};

const { options } = defineProps<{
	options: ModalOptions;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const isDisable = ref(false);
const items = markRaw([
	{
		id: 1,
		icon: PiPalette,
		title: ANNOUCEMENT_V2_LIST_1_TITLE,
		subtitle: ANNOUCEMENT_V2_LIST_1_TEASER,
	},
	{
		id: 2,
		icon: PiHandCoinsDuotone,
		title: ANNOUCEMENT_V2_LIST_2_TITLE,
		subtitle: ANNOUCEMENT_V2_LIST_2_TEASER,
	},
	{
		id: 3,
		icon: PiNavbar,
		title: ANNOUCEMENT_V2_LIST_3_TITLE,
		subtitle: ANNOUCEMENT_V2_LIST_3_TEASER,
	},
	{
		id: 4,
		icon: PiHcRocket,
		title: ANNOUCEMENT_V2_LIST_4_TITLE,
		subtitle: ANNOUCEMENT_V2_LIST_4_TEASER,
	},
]);

const closeButtonId = computed(() =>
	options.channel === MessageChannels.ANNOUNCEMENT
		? 'close-announcement-button'
		: 'close-modal-button'
);

const handleSubmit = () => {
	isDisable.value = true;
	emit('close-modal');
};
</script>
<template>
	<div class="annoucement-modal">
		<div class="annoucement-header">
			<PrimeText weight="600" align="center">
				{{ $t(ANNOUCEMENT_V2_HEAD, { version: '2.0' }) }}
			</PrimeText>
			<button
				class="annoucement-close-button"
				:data-test-id="closeButtonId"
				@click="handleSubmit"
			>
				<PiClose />
			</button>
		</div>

		<div class="annoucement-content">
			<div class="icon">
				<img :src="rocket" alt="rocket" />
			</div>

			<PrimeText
				weight="600"
				align="center"
				size="lg"
				class="annoucement-title"
			>
				{{ $t(ANNOUCEMENT_V2_TITLE, { version: '2.0' }) }} 🚀
			</PrimeText>

			<PrimeText
				weight="500"
				align="center"
				size="sm"
				color="grey-700"
				class="annoucement-subtitle"
			>
				{{ $t(ANNOUCEMENT_V2_SUBTITLE) }}
			</PrimeText>

			<div class="annoucement-info">
				<div v-for="item in items" :key="item.id" class="annoucement-item">
					<component :is="item.icon" color="inherit" class="annoucement-icon" />
					<div>
						<PrimeText size="xs" weight="700"> {{ $t(item.title) }} </PrimeText
						>:
						<PrimeText size="xs"> {{ $t(item.subtitle) }}</PrimeText>
					</div>
				</div>
			</div>
		</div>
		<div class="annoucement-bottom">
			<PrimeButton
				type="fill"
				full-width
				:label="$t(ANNOUCEMENT_V2_BTN, { version: '2.0' })"
				:disabled="isDisable"
				rounded="rounded"
				@click="handleSubmit"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
@import '@/styles/mixins.scss';

.annoucement-modal {
	display: flex;
	flex-direction: column;
	max-height: 100%;
}

.annoucement-header {
	position: relative;
	flex: none;
	min-height: 1.5rem;
	display: flex;
	justify-content: center;
	padding: 1rem 2.4rem 1rem;
	border-bottom: 1px solid var(--grey-200);

	@include breakpoint(mobile) {
		justify-content: flex-start;
		padding: 1rem 3.25rem 1rem 0.875rem;
	}
}

.annoucement-close-button {
	position: absolute;
	top: 1rem;
	right: 0.875rem;
	z-index: 5;
	cursor: pointer;
	background-color: transparent;
	border: none;
}

.annoucement-content {
	overflow: auto;
	padding: 0 1.5rem;
}

.icon {
	display: flex;
	justify-content: center;
	margin: 1.375rem 0;
}

.annoucement-title {
	display: block;
	width: 100%;
}

.annoucement-subtitle {
	display: block;
	width: 100%;
	margin-top: 0.75rem;
}

.annoucement-info {
	width: 100%;
	padding: 1rem 0.875rem;
	border-radius: 0.75rem;
	background: var(--base-primary-50);
	margin-top: 1.375rem;
	color: var(--base-primary);
}

.annoucement-item {
	display: flex;
	align-items: flex-start;
	gap: 0.75rem;
	margin-bottom: 0.875rem;
}

.annoucement-item:last-child {
	margin-bottom: 0;
}

.annoucement-icon {
	flex: none;
}

.annoucement-bottom {
	flex: none;
	padding: 1.375rem 1.5rem 1.5rem;
}
</style>
