import { onBeforeUnmount, onMounted } from 'vue';

export const usePostMessageHandler = (
	handlerFunction: (event: MessageEvent) => void
): void => {
	onMounted(() => {
		window.addEventListener('message', handlerFunction);
	});

	onBeforeUnmount(() => {
		window.removeEventListener('message', handlerFunction);
	});
};
