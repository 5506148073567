import { useModalStorage } from '@/utils/composables/useModalStorage';
import { GOOGLE_TRANSLATE_MODAL } from '@/constants/modals';
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

export const useTranslateDetection = () => {
	const modalStorage = useModalStorage();
	const { userLocales } = storeToRefs(useUserStore());

	const observeGoogleTranslate = async () => {
		const googleTranslateObserver = new MutationObserver(async () => {
			if (
				document.documentElement.className.match('translated') &&
				userLocales.value &&
				userLocales.value.length > 1
			) {
				await modalStorage.pushModal({
					name: GOOGLE_TRANSLATE_MODAL,
				});
			}
		});

		googleTranslateObserver.observe(document.documentElement, {
			attributes: true,
			attributeFilter: ['class'],
			childList: false,
			characterData: false,
		});
	};

	onMounted(async () => {
		await observeGoogleTranslate();
	});
};
