<script lang="ts" setup>
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRouter } from 'vue-router';
import type { DynamicModalContent } from '@/types';
import { computed } from 'vue';

const { options } = defineProps<{
	options: {
		content: DynamicModalContent;
	};
}>();

const content = computed(() => options.content);

const emit = defineEmits<{
	'close-modal': [];
}>();

const router = useRouter();

const handleCloseModal = () => {
	if (content.value.button?.button_link) {
		router.push(content.value.button.button_link);
	}
	emit('close-modal');
};
</script>

<template>
	<div class="dynamic-announcement-modal">
		<ModalCommonContent>
			<template #header>
				<PrimeText tag="div" v-html="content.header" />
			</template>
			<template v-if="content.image_url" #icon>
				<span class="dynamic-image" v-html="content.image_url" />
			</template>
			<template v-if="content.title" #title>
				<PrimeText tag="div" v-html="content.title" />
			</template>
			<template v-if="content.description" #description>
				<PrimeText tag="div" v-html="content.description" />
			</template>
			<template v-if="content.message" #message>
				<PrimeText tag="div" v-html="content.message" />
			</template>
			<template v-if="content.button" #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="content.button?.button_text"
					@click="handleCloseModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.dynamic-announcement-modal {
	:deep(.modal-common-content) {
		.content-icon {
			display: flex;
			width: initial;
			height: initial;
		}
	}
}
</style>
