import API from '@/api';
import type { Announcement } from '@/types';

export const getAnnouncements = async (): Promise<Announcement[]> => {
	const { data } = await API.get<Announcement[]>('/api/announcements');
	return data;
};

export const setAnnouncementAsRead = async (id: string): Promise<void> => {
	await API.put(`/api/announcements/${id}/mark-as-read`);
};
