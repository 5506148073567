<script lang="ts" setup>
import {
	OFFERWALLS_ARE_HERE,
	OFFERWALLS_NEW_EARNING,
	OFFERWALLS_ENABLE_EARNING,
	OFFERWALLS_BUTTON_DESCRIPTION,
	OFFERWALLS_OPEN,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PiCoinsDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRouter } from 'vue-router';

const router = useRouter();

const emits = defineEmits<{
	'close-modal': [];
}>();

const handleClick = () => {
	router.push({ name: 'offerwalls-partners' });
	emits('close-modal');
};
</script>

<template>
	<div id="offerwalls-onetime-notification-modal">
		<ModalCommonContent>
			<template #header>{{ $t(OFFERWALLS_ARE_HERE) }}</template>
			<template #icon>
				<PiCoinsDuotoneThin color="base-primary" size="100%" />
			</template>
			<template #title>
				<PrimeText tag="div" weight="500">
					{{ $t(OFFERWALLS_NEW_EARNING) }}
				</PrimeText>
			</template>
			<template #description>
				<PrimeText tag="div" size="sm" color="grey-700">
					{{ $t(OFFERWALLS_ENABLE_EARNING) }}
				</PrimeText>
			</template>
			<template #message>
				<PrimeText tag="div" size="sm" color="grey-700">
					{{ $t(OFFERWALLS_BUTTON_DESCRIPTION) }}
				</PrimeText>
			</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(OFFERWALLS_OPEN)"
					@click="handleClick"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
#offerwalls-onetime-notification-modal {
	:deep(.modal-common-content) {
		.content-description {
			max-width: 85%;
			border-bottom: none;
		}
	}
}
</style>
