import { Platforms } from '@/enums';
import { Capacitor } from '@capacitor/core';
import { isInAppBrowserStorage, platformStorage } from '@/utils/local-storage';
import { computed, type ComputedRef } from 'vue';

export const useMobileApp = (): {
	isMobileApp: boolean;
	platform: ComputedRef<Platforms>;
	isWebMobile: ComputedRef<boolean>;
	isAndroid: ComputedRef<boolean>;
	isIos: ComputedRef<boolean>;
	isWeb: ComputedRef<boolean>;
	isInAppBrowser: ComputedRef<boolean>;
} => {
	const isMobileApp = Capacitor.isNativePlatform();
	const platform = computed(
		() => (platformStorage.value as Platforms) || Platforms.WEB
	);

	const isWeb = computed(() => platform.value === Platforms.WEB);
	const isWebMobile = computed(() => platform.value === Platforms.WEB_MOBILE);
	const isAndroid = computed(() => platform.value === Platforms.ANDROID);
	const isIos = computed(() => platform.value === Platforms.IOS);
	const isInAppBrowser = computed(
		() => isWebMobile.value && isInAppBrowserStorage.value
	);

	return {
		isMobileApp,
		platform,
		isWeb,
		isWebMobile,
		isAndroid,
		isIos,
		isInAppBrowser,
	};
};
