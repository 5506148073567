import type { Timezone } from '@/types';

export const timezones = <Timezone[]>[
	{ code: 'Pacific/Honolulu', label: '-10:00 Hawaii-Aleutian Honolulu' },
	{ code: 'America/Anchorage', label: '-09:00 Alaska Anchorage' },
	{ code: 'America/Los_Angeles', label: '-08:00 Pacific Time (US & Canada)' },
	{
		code: 'America/Denver',
		label: '-07:00 Mountain Time (US & Canada) Denver',
	},
	{
		code: 'America/Chicago',
		label: '-06:00 Central Time (US & Canada) Chicago',
	},
	{
		code: 'America/New_York',
		label: '-05:00 Eastern Time (US & Canada) New York',
	},
	{ code: 'America/Mexico_City', label: '-06:00 Central Time Mexico City' },
	{ code: 'America/Toronto', label: '-05:00 Eastern Time Toronto' },
	{ code: 'America/Bogota', label: '-05:00 Colombia Time Bogota' },
	{ code: 'America/Sao_Paulo', label: '-03:00 Brasília Time Sao Paulo' },
	{
		code: 'America/Argentina/Buenos_Aires',
		label: '-03:00 Argentina Time Buenos Aires',
	},
	{ code: 'America/Santiago', label: '-03:00 Chile Time Santiago' },
	{ code: 'Atlantic/Azores', label: '-01:00 Azores' },
	{ code: 'Europe/Lisbon', label: '+00:00 Lisbon' },
	{ code: 'Europe/Vienna', label: '+01:00 CET Vienna' },
	{ code: 'Europe/Brussels', label: '+01:00 CET Brussels' },
	{ code: 'Europe/Zurich', label: '+01:00 CET Zurich' },
	{ code: 'Europe/Prague', label: '+01:00 CET Prague' },
	{ code: 'Europe/Berlin', label: '+01:00 CET Berlin' },
	{ code: 'Europe/Copenhagen', label: '+01:00 CET Copenhagen' },
	{ code: 'Europe/Madrid', label: '+01:00 CET Madrid' },
	{ code: 'Europe/Paris', label: '+01:00 CET Paris' },
	{ code: 'Europe/Rome', label: '+01:00 CET Rome' },
	{ code: 'Europe/Amsterdam', label: '+01:00 CET Amsterdam' },
	{ code: 'Europe/Oslo', label: '+01:00 CET Oslo' },
	{ code: 'Europe/Warsaw', label: '+01:00 CET Warsaw' },
	{ code: 'Europe/Stockholm', label: '+01:00 Stockholm' },
	{ code: 'Europe/Helsinki', label: '+02:00 Eastern European Time Helsinki' },
	{ code: 'Europe/Bucharest', label: '+02:00 Bucharest' },
	{ code: 'Africa/Johannesburg', label: '+02:00 Johannesburg' },
	{ code: 'Asia/Riyadh', label: '+03:00 Riyadh' },
	{ code: 'Europe/Istanbul', label: '+03:00 Istanbul' },
	{
		code: 'Asia/Dubai',
		label: '+04:00 United Arab Emirates Standard Time Dubai',
	},
	{ code: 'Asia/Kolkata', label: '+05:30 India Standard Time Kolkata' },
	{ code: 'Asia/Jakarta', label: '+07:00 Western Indonesia Time Jakarta' },
	{ code: 'Asia/Bangkok', label: '+07:00 Bangkok' },
	{ code: 'Asia/Ho_Chi_Minh', label: '+07:00 Ho Chi Minh' },
	{ code: 'Asia/Hong_Kong', label: '+08:00 Hong Kong Time' },
	{ code: 'Asia/Manila', label: '+08:00 Philippine Time Manila' },
	{
		code: 'Australia/Sydney',
		label: '+11:00 Australian Eastern Standard Time Sydney',
	},
	{ code: 'Europe/London', label: '(GMT) Greenwich Mean Time London' },
	{ code: 'Europe/Dublin', label: '(GMT) Greenwich Mean Time Dublin' },
	{
		code: 'Asia/Kuala_Lumpur',
		label: '+08:00 Malaysia Time Kuala Lumpur',
	},
	{ code: 'Asia/Singapore', label: '+08:00 Singapore' },
	{ code: 'Asia/Tokyo', label: '+09:00 Japan Standard Time Tokyo' },
	{ code: 'Asia/Seoul', label: '+09:00 Korea Standard Time Seoul' },
	{
		code: 'Pacific/Auckland',
		label: '+13:00 New Zealand Standard Time Auckland',
	},
];
