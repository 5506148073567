import type { Validation, ValidationArgs } from '@vuelidate/core';
import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

export const useValidationUtils = <T extends ValidationArgs<unknown>>(
	validation: Ref<Validation<T>>
): {
	getValidationFieldStatus: (field: keyof T) => 'error' | null | undefined;
	getValidationFieldText: (field: keyof T) => string | null | undefined;
	isValidationError: ComputedRef<boolean>;
	reset: () => void;
	clear: () => void;
	validate: () => Promise<boolean>;
	clearAllValidation: () => void;
} => {
	const getValidationFieldStatus = (field: keyof T) => {
		const currentField = validation.value[field];
		if (currentField) {
			return currentField.$error && !currentField.$pending ? 'error' : null;
		} else {
			return null;
		}
	};

	const getValidationFieldText = (field: keyof T) => {
		const currentField = validation.value[field];
		if (currentField) {
			return currentField.$error
				? (currentField.$errors[0].$message as string)
				: null;
		} else {
			return null;
		}
	};

	const reset = () => validation.value.$reset();
	const validate = () => validation.value.$validate();
	const clear = () => validation.value.$clearExternalResults();

	const isValidationError = computed(() => validation.value.$error);

	const clearAllValidation = () => {
		clear();
		reset();
	};

	return {
		reset,
		validate,
		isValidationError,
		clear,
		getValidationFieldStatus,
		getValidationFieldText,
		clearAllValidation,
	};
};
