<script setup lang="ts">
import { useSlots } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ImportantNote from '@/components/ImportantNote.vue';

const slots = useSlots();
</script>
<template>
	<div class="modal-common-conten-v2">
		<PrimeText
			v-if="slots.header"
			tag="h2"
			weight="600"
			class="content-header"
			transform="capitalize"
		>
			<slot name="header" />
		</PrimeText>

		<span v-if="slots.icon" class="icon">
			<slot name="icon" />
		</span>

		<div v-if="slots.title" class="title">
			<PrimeText
				v-if="slots.header"
				tag="h2"
				weight="600"
				size="lg"
				color="dark"
			>
				<slot name="title" />
			</PrimeText>
		</div>

		<div v-if="slots.box" class="box">
			<slot name="box" />
		</div>

		<PrimeText
			v-if="slots.message"
			tag="p"
			size="sm"
			color="grey-700"
			weight="500"
			class="content-message"
		>
			<slot name="message" />
		</PrimeText>

		<div v-if="slots.note" class="note">
			<ImportantNote><slot name="note" /></ImportantNote>
		</div>

		<div v-if="slots.custom" class="custom">
			<slot name="custom" />
		</div>

		<div
			v-if="slots.actions"
			class="content-actions"
			:class="{
				'inputs-actions': slots.inputs,
			}"
		>
			<slot name="actions" />
		</div>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.modal-common-conten-v2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	max-height: 80vh;

	.content-header {
		border-bottom: 1px solid var(--grey-200);
		margin: 0 -0.625rem 1.375rem;
		padding: 0 2.5rem 1.25rem 0.875rem;
		width: calc(100% + 1.25rem);
		text-align: left;

		@include breakpoint(mobile) {
			margin: 0 -1.5rem 1.5rem;
			padding-left: 1.5rem;
			padding-bottom: 1rem;
			width: calc(100% + 3rem);
		}
	}

	.title {
		margin-top: 1.375rem;
	}

	.icon {
		img,
		svg {
			display: block;
			max-width: 9.375rem;
			height: auto;
		}
		@include breakpoint(mobile) {
			img,
			svg {
				max-width: 12.5rem;
			}
		}
	}

	.box {
		width: 100%;
		padding: 0.625rem;
		background: var(--base-primary-09);
		border-radius: 0.5rem;
		margin-top: 1.375rem;

		& > * {
			margin-top: 0.25rem;
		}
		& > *:first-child {
			margin-top: 0;
		}
	}

	.content-message {
		margin-top: 0.75rem;
		text-align: center;
		white-space: pre-line;
	}

	.content-actions {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 1.375rem;

		& > * {
			margin-bottom: 0.75rem;
		}
		& > *:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(mobile) {
			flex-direction: row;

			& > * {
				margin-right: 0.75rem;
				margin-bottom: 0;
			}
			& > *:last-child {
				margin-right: 0;
			}
		}
	}

	.note {
		width: 100%;
	}

	.note-content-wrapper {
		margin: 0;
	}

	.custom {
		width: calc(100% + 0.5rem);
		margin-top: 1.25rem;
		overflow: auto;
		padding-right: 0.5rem;
		margin-right: -0.5rem;
	}
}
</style>
