<script lang="ts" setup>
import { ref, computed } from 'vue';
import {
	OKAY,
	WELL_DONE,
	OFFERWALLS_REWARD,
	OFFERWALLS_WELL_DONE_MODAL_TITLE,
	OFFERWALLS_WELL_DONE_MODAL_TEASER,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PiConfettiDoutoneThin } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { OfferwallReward, OfferwallTransactionDelayed } from '@/types';
import { localizePoints } from '@/utils/helpers';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
	options: OfferwallReward | undefined;
	data: OfferwallTransactionDelayed | undefined;
}>();

const emits = defineEmits<{
	'close-modal': [];
}>();
const { t } = useI18n();

const isDisabled = ref(false);

const onClose = () => {
	isDisabled.value = true;
	emits('close-modal');
};

const title = computed(() =>
	props.data
		? t(OFFERWALLS_WELL_DONE_MODAL_TITLE, {
				name: props.data?.offer_name,
				points: localizePoints(props.data?.coin_value),
			})
		: t(OFFERWALLS_REWARD, {
				coins: props.options?.coins_earned,
				name: props.options?.offer_name,
			})
);
const teaser = computed(() =>
	props.data
		? t(OFFERWALLS_WELL_DONE_MODAL_TEASER, { days: props.data?.credit_delay })
		: null
);
</script>

<template>
	<div class="one-click-survey">
		<ModalCommonContent>
			<template #header>{{ $t(WELL_DONE) }}</template>
			<template #icon>
				<PiConfettiDoutoneThin size="100%" color="base-primary" />
			</template>
			<template #title>
				<PrimeText v-html="title" />
			</template>
			<template v-if="teaser" #message>
				{{ teaser }}
			</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(OKAY)"
					:disabled="isDisabled"
					@click="onClose"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.one-click-survey {
	:deep(.modal-common-content) {
		.content-title {
			margin-bottom: 0.75rem;
		}

		.content-icon {
			margin-bottom: 0.75rem;
		}

		.content-description {
			margin-bottom: 1.375rem;
			padding-bottom: 1.375rem;
		}

		.content-actions {
			margin-top: 1.375rem;
		}
	}
}
</style>
