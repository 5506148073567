import { defineStore } from 'pinia';
import { getUser, getUserLevelTiers, getUserMeta } from '@/api';
import type {
	ErrorResponse,
	FeatureFlagsData,
	Leaderboard,
	LevelTier,
	Locale,
	OfferwallMeta,
	SelectedReward,
	SelectedRewardOption,
	TiersDetails,
	UserAccount,
	UserLevel,
	UserMeta,
	UserStreak,
	OfferwallPendingEvents,
} from '@/types';
import type { AxiosError } from 'axios';
import { getRootDomain } from '@/utils/helpers';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useRouter } from 'vue-router';
import { handleUserLocale } from '@/i18n';
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import { Capacitor } from '@capacitor/core';
import { fetchUserFeatures } from '@/api/fetures';
import { getOfferWallPending, getLeaderboardMeta } from '@/api';
import { FeatureFlags } from '@/enums';

interface UserState {
	data: UserAccount | null;
	tiersDetails: TiersDetails | null;
	message: string;
	loading: boolean;
	error: ErrorResponse | null;
	initialDataLoading: boolean;
	features: FeatureFlagsData | null;
	meta: UserMeta | null;
	offerwallsPending: OfferwallPendingEvents | null;
	leaderboardRank: string;
	leaderboardDefault: Leaderboard;
}

export const useUserStore = defineStore('user', {
	state: (): UserState => ({
		data: null,
		tiersDetails: null,
		message: '',
		loading: false,
		error: null,
		initialDataLoading: false,
		features: null,
		meta: null,
		offerwallsPending: null,
		leaderboardRank: '',
		leaderboardDefault: {
			active: false,
			has_enough_completes: false,
			successful_completes: 0,
			total_completes_required_to_unlock: 0,
			earned: 0,
			rank: '100',
			unlocked: false,
			record: '+99',
		},
	}),
	getters: {
		isStoreLoaded: (state) => state.data && !state.error,
		userId: (state) => state.data?.id,
		userHash: (state) => state.data?.hash,
		username: (state) => state.data?.username || '',
		email: (state) => state.data?.email,
		timezone: (state) => state.data?.timezone || '',
		countryCode: (state) => state.data?.country?.code.toLowerCase() || '',
		countryName: (state) => state.data?.country?.name || '',
		createdAt: (state) => state.data?.created_at || '',
		maxPrize: (state) => state.data?.streak?.max_prize_value || 0,
		streakDayStart: (state) => state.data?.streak?.start_day || 0,
		weekLenght: (state) => state.data?.streak?.cycle_time || 7,
		prizes: (state) => state.data?.streak?.prizes || 0,
		streaks: (state) => state.data?.streak?.length || 0,
		completedDays: (state) => state.data?.streak?.days_in_current || 0,
		isTodayGoalCompleted: (state) => state.data?.streak?.daily_goal_complete,
		leaderboard: (state): Leaderboard =>
			state.data?.leaderboard || state.leaderboardDefault,
		levelActual: (state) => state.data?.level?.actual || 0,
		levelNext: (state) => state.data?.level?.next || 1,
		levelReward: (state) => state.data?.level?.reward || 0,
		levelRewardEarned: (state) => state.data?.level?.reward_earned || 0,
		coinsEarned: (state) => state.data?.level?.coins_earned || 0,
		coinsTarget: (state) => state.data?.level?.coins_target || 100,

		isWelcomeBonusSelected: (state) => !!state.data?.bonus,
		isWelcomeBonusClaimed: (state) => !!state.data?.bonus?.claimed_at,
		minWelcomeBonusClaimBalance: (state) =>
			state.data?.bonus?.min_claim_balance || 0,
		userData: (state) => state.data,

		collectedCoins: (state) => state.data?.coins_balance || 0,

		refLink: (state) => `${getRootDomain(true)}/register?ref=${state.data?.id}`,
		userSelectedReward: (state): SelectedReward | null =>
			state.data?.selected_reward || null,
		selectedRewardOption: (state): SelectedRewardOption | null => {
			if (state.data?.selected_reward) {
				return {
					reward_external_id: state.data?.selected_reward.reward_external_id,
					money_value: state.data?.selected_reward.money_value,
				};
			}

			return null;
		},
		welcomeBonusAmount: (state) => state.data?.bonus?.coin_value || 0,
		currency: (state) => state.data?.country.currency || '',
		source: (state) => state.data?.s || '',
		userLocales: (state): Locale[] => state.data?.country.locales!,
		isPasswordSet: (state) =>
			state.data && 'is_password_set' in state.data
				? state.data?.is_password_set
				: true,
		currentTier: (state): LevelTier => state.data?.level!.tier!,
		tiersData: (state): TiersDetails | null => state.tiersDetails || null,
		currencyBalance: (state) => state.data?.currency_balance,
		lastClaimEmail: (state) => state.data?.last_claim_email || '',
		userLocale: (state) => state.data?.locale || 'en-us',
		isBonusDayUnlocked: (state) => state.data?.bonus_day.is_available,
		bonusDayBonus: (state) =>
			state.data ? state.data?.bonus_day.percentage + '%' : '',
		// TODO Hardcoded for now
		rewardsDiscount: () => '5%',
		revolutFullName: (state) =>
			state.data?.last_revolut_counterparty.full_name || '',
		revolutTag: (state) => state.data?.last_revolut_counterparty.tag || '',
		lastUsedAchFullName: (state) =>
			state.data?.last_claim_counterparty_name || '',
		isInitialDataLoading: (state) => state.initialDataLoading,

		// Feature flags checks
		availableUserFeatures: (state) =>
			state.features ? Object.keys(state.features) : [],
		isOfferwallsAvailable: (state) =>
			state.features
				? Object.keys(state.features).includes(FeatureFlags.OFFER_WALLS)
				: false,
		offerwallsMeta: (state) =>
			state.features && state.features.offer_walls
				? (state.features.offer_walls as OfferwallMeta)
				: null,

		isPrimeChatAvailableForUser: (state) =>
			state.data?.is_chat_available || false,
		userMeta: (state) => state.meta,
		referralBonusCode: (state) => state.meta?.ref_bonus_code || '',
		appReviewProbability: (state) => state.meta?.app_review_probability || 0,
		offerwallsPendings: (state) =>
			state.offerwallsPending?.pending_events || [],
		offerwallsPoints: (state) => state.offerwallsPending?.pending_points || 0,
		currentLeaderboardRank: (state) => state.leaderboardRank || '',
		isDarkModeEnabled: (state) =>
			state.features ? !!state.features.dark_mode : undefined,
		isPsOffersEnabled: (state) =>
			state.features ? !!state.features.ps_offers : false,
	},
	actions: {
		async fetchOfferwallPending() {
			this.offerwallsPending = await getOfferWallPending();
		},
		async fetchUserData() {
			try {
				this.loading = true;
				this.data = await getUser();
				this.setLeaderboardUserRank(this.data.leaderboard.rank);
				await handleUserLocale(this.data.locale);
				SentryVue.setTag('user_id', this.data.id);
				if (Capacitor.isNativePlatform()) {
					Sentry.setTag('user_id', this.data.id);
				}
			} catch (error) {
				const axiosErrorResponse = (error as AxiosError).response;
				if (axiosErrorResponse?.status === 401) {
					const router = useRouter();
					await router.replace('login');
				} else {
					this.error = axiosErrorResponse?.data as ErrorResponse;
				}
				sendErrorInfo(error);
			} finally {
				this.loading = false;
			}
		},
		async fetchTiersList() {
			if (this.data) {
				this.tiersDetails = await getUserLevelTiers();
			}
		},
		async fetchUserMeta() {
			try {
				this.meta = await getUserMeta();
			} catch (error) {
				console.log('Error fetching user meta', error);
			}
		},
		async fetchFeatures() {
			this.features = await fetchUserFeatures();
		},
		updateLeaderboardData(result: Leaderboard) {
			if (!this.data || !result) return;
			for (const key in result) {
				if (!this.data?.leaderboard) {
					this.data.leaderboard = this.leaderboardDefault;
				}
				(this.data?.leaderboard as any)[key] = result[key as keyof Leaderboard];
			}
		},
		async fetchLeaderboardData() {
			const result = await getLeaderboardMeta();
			this.updateLeaderboardData(result.user_status);
			return result;
		},
		setCollectedCoins(value: number) {
			if (this.data) {
				this.data.coins_balance = value;
			}
		},
		setCollectedCoinsBalance(value: string) {
			if (this.data) {
				this.data.currency_balance = value;
			}
		},
		setLevel(level: UserLevel) {
			if (this.data?.level) {
				this.data.level = level;
			}
		},
		setStreak(streak: UserStreak) {
			if (this.data?.streak) {
				this.data.streak = streak;
			}
		},
		setUsername(value: string) {
			if (this.data?.username !== undefined) {
				this.data.username = value;
			}
		},
		setTimezone(value: string) {
			if (this.data?.timezone && this.data.timezone !== value) {
				this.data.timezone = value;
			}
		},
		setInitialDataLoading(value: boolean) {
			this.initialDataLoading = value;
		},
		setLeaderboardUserRank(value: string) {
			this.leaderboardRank = value;
		},
	},
});
