<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import {
	onMounted,
	onUnmounted,
	ref,
	watch,
	onBeforeUnmount,
	computed,
} from 'vue';
import { useClickOutside } from '@/utils/composables/useClickOutside';
import { PiClose } from '@primeinsightsgroupllc/prime-icons';
import ModalTransition from '@/components/modals/ModalTransition.vue';
import { MessageChannels } from '@/enums';

const props = withDefaults(
	defineProps<{
		show: boolean;
		useDefaultClose?: boolean;
		position?: 'bottom' | null;
		animate?: boolean;
		data?: any;
		isScrolable?: boolean;
		dataTestId?: string;
		channel?: string;
	}>(),
	{
		useDefaultClose: true,
		position: null,
		animate: true,
		data: null,
		isScrolable: false,
		dataTestId: '',
		channel: '',
	}
);

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const close = () => {
	emit('close-modal');
};

const modalContainerRef = ref();
const isModalDisplay = ref(false);
const timeout = ref<any>(null);

const useModalAnimation = ref(true);

const closeButtonId = computed(() =>
	props.channel === MessageChannels.ANNOUNCEMENT
		? 'close-announcement-button'
		: 'close-modal-button'
);

const handleKeyup = (event: KeyboardEvent) => {
	if (event.key === 'Escape') {
		close();
	}
};

watch(
	() => props.show,
	(value) => {
		if (value) {
			useModalAnimation.value = props.animate;
		} else {
			setTimeout(() => {
				useModalAnimation.value = true;
			}, 1000);
		}

		if (!value && props.position === 'bottom') {
			// Set timeout for transition duration on close
			timeout.value = setTimeout(() => {
				isModalDisplay.value = value;
				clearTimeout(timeout.value);
			}, 550);
		} else {
			isModalDisplay.value = value;
		}
	},
	{ immediate: true }
);

onMounted(() => {
	document.addEventListener('keyup', handleKeyup);
	useClickOutside(modalContainerRef, close);
});

onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});

onUnmounted(() => {
	document.removeEventListener('keyup', handleKeyup);
});
</script>

<template>
	<ModalTransition
		:key="useModalAnimation ? 'animated' : 'no-animated'"
		:animate="useModalAnimation"
	>
		<div v-if="isModalDisplay" class="modal-mask">
			<div
				ref="modalContainerRef"
				class="modal-container"
				:data-test-id="dataTestId"
				:class="[
					$attrs.class,
					position,
					{ show },
					{ 'scrolable-content': isScrolable },
				]"
			>
				<button
					v-if="useDefaultClose && !isScrolable"
					class="modal-close-button"
					:data-test-id="closeButtonId"
					@click.stop="$emit('close-modal')"
				>
					<PiClose />
				</button>
				<div class="modal-content">
					<slot />
				</div>
			</div>
		</div>
	</ModalTransition>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.modal-mask {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(32, 34, 35, 0.7);
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;

	@supports (inset: 0) {
		inset: 0;
	}

	@supports not (inset: 0) {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.modal-container {
	background-color: var(--white);
	position: relative;
	padding: 1.25rem 0.75rem 1.5rem;
	border-radius: 0.75rem;
	max-width: 34.375rem;
	width: 95vw;
	max-height: calc(100% - 5rem);
	display: flex;
	flex-direction: column;

	&.scrolable-content {
		overflow: hidden;
		max-height: calc(100% - 5.5rem);
		padding: 0;
		flex-direction: unset;

		.modal-content {
			overflow: auto;
		}
	}

	@include breakpoint(mobile) {
		padding: 1rem 1.5rem 1.5rem;
	}

	&.bottom {
		align-self: flex-end;
		padding-left: 1rem;
		padding-right: 1rem;
		width: 100vw;
		max-width: 40.625rem;
		border-radius: 1.5rem 1.5rem 0 0;
		transform: translateY(100%);
		animation: modal-slide-down 0.45s;

		@include breakpoint(mobile) {
			padding: 1.5rem;
		}

		&.show {
			animation: modal-slide-up 0.45s forwards;
		}
	}

	.modal-content {
		display: flex;
		flex-direction: column;
	}

	.modal-close-button {
		top: 1.125rem;
		right: 0.875rem;
		position: absolute;
		cursor: pointer;
		background-color: transparent;
		border: none;
		z-index: 10;

		@include breakpoint(mobile) {
			top: 1rem;
		}
	}
}

@keyframes modal-slide-up {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes modal-slide-down {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100%);
	}
}
</style>
