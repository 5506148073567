export const currencies: Record<string, string> = {
	AED: 'د.إ',
	AFN: 'Af',
	ALL: 'L',
	AMD: 'Դ',
	AOA: 'Kz',
	ARS: '$',
	AUD: '$',
	AWG: 'ƒ',
	AZN: 'ман',
	BAM: 'КМ',
	BBD: '$',
	BDT: '৳',
	BGN: 'лв',
	BHD: 'ب.د',
	BIF: '₣',
	BMD: '$',
	BND: '$',
	BOB: 'Bs.',
	BRL: 'R$',
	BSD: '$',
	BWP: 'P',
	BYN: 'Br',
	BZD: '$',
	CAD: '$',
	CDF: '₣',
	CHF: '₣',
	CLP: '$',
	CNY: '¥',
	COP: '$',
	CRC: '₡',
	CUP: '$',
	CVE: '$',
	CZK: 'Kč',
	DJF: '₣',
	DKK: 'kr',
	DOP: '$',
	DZD: 'د.ج',
	EGP: '£',
	ERN: 'Nfk',
	EUR: '€',
	FJD: '$',
	FKP: '£',
	GBP: '£',
	GEL: 'ლ',
	GHS: '₵',
	GIP: '£',
	GMD: 'D',
	GNF: '₣',
	GTQ: 'Q',
	GYD: '$',
	HKD: '$',
	HNL: 'L',
	HRK: 'Kn',
	HTG: 'G',
	HUF: 'Ft',
	IDR: 'Rp',
	ILS: '₪',
	INR: '₹',
	IQD: 'ع.د',
	IRR: '﷼',
	ISK: 'Kr',
	JMD: '$',
	JOD: 'د.ا',
	JPY: '¥',
	KES: 'Sh',
	KHR: '៛',
	KPW: '₩',
	KRW: '₩',
	KWD: 'د.ك',
	KYD: '$',
	KZT: '〒',
	LAK: '₭',
	LBP: 'ل.ل',
	LKR: 'Rs',
	LRD: '$',
	LSL: 'L',
	LYD: 'ل.د',
	MAD: 'د.م.',
	MDL: 'L',
	MKD: 'ден',
	MMK: 'K',
	MNT: '₮',
	MOP: 'P',
	MRU: 'UM',
	MUR: '₨',
	MVR: 'ރ.',
	MWK: 'MK',
	MXN: '$',
	MYR: 'RM',
	MZN: 'MTn',
	NAD: '$',
	NGN: '₦',
	NIO: 'C$',
	NOK: 'kr',
	NPR: '₨',
	NZD: '$',
	OMR: 'ر.ع.',
	PAB: 'B/.',
	PEN: 'S/.',
	PGK: 'K',
	PHP: '₱',
	PKR: '₨',
	PLN: 'zł',
	PYG: '₲',
	QAR: 'ر.ق',
	RON: 'L',
	RSD: 'din',
	RUB: 'р.',
	RWF: '₣',
	SAR: 'ر.س',
	SBD: '$',
	SCR: '₨',
	SDG: '£',
	SEK: 'kr',
	SGD: '$',
	SHP: '£',
	SLL: 'Le',
	SOS: 'Sh',
	SRD: '$',
	STN: 'Db',
	SYP: 'ل.س',
	SZL: 'L',
	THB: '฿',
	TJS: 'ЅМ',
	TMT: 'm',
	TND: 'د.ت',
	TOP: 'T$',
	TRY: '₤',
	TTD: '$',
	TWD: '$',
	TZS: 'Sh',
	UAH: '₴',
	UGX: 'Sh',
	USD: '$',
	UYU: '$',
	VEF: 'Bs F',
	VND: '₫',
	VUV: 'Vt',
	WST: 'T',
	XAF: '₣',
	XCD: '$',
	XPF: '₣',
	YER: '﷼',
	ZAR: 'R',
	ZMW: 'ZK',
	ZWL: '$',
};
