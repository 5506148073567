<script lang="ts" setup>
import { onErrorCaptured } from 'vue';
import { sendErrorInfo } from '@/utils/errorCatching';

onErrorCaptured((error, vm, info) => {
	const component = vm?.$.type.__name || '';
	sendErrorInfo(error, component, { info });
});
</script>

<template>
	<slot></slot>
</template>
