<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRewardsStore } from '@/stores/rewards';
import { useUserStore } from '@/stores/user';
import { Platforms, type RewardCategories } from '@/enums';
import {
	NO_REWARDS_MESSAGE,
	NO_RESULTS,
	NO_REWARDS_WITH_NAME,
	SEARCH_REWARDS,
	SELECT,
	SELECT_REWARDS,
} from '@/locales/constants';
import type { RewardsItem } from '@/types';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import RewardsCategory from '@/views/surveys/components/user-dashboard/rewards/RewardsCategory.vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiClose } from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeButton,
	PrimeSkeleton,
	PrimeText,
	PrimeInput,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiSearch } from '@primeinsightsgroupllc/prime-icons';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { storeToRefs } from 'pinia';
import PromotedRewards from '@/views/surveys/components/user-dashboard/rewards/PromotedRewards.vue';
import { RewardCategories as Categories } from '@/enums';
import RewardsCashback from '@/views/surveys/components/user-dashboard/rewards/RewardsCashback.vue';

const { open } = defineProps<{
	open: boolean;
}>();

const emit = defineEmits<{
	'close-modal': [];
}>();

const { platform } = useMobileApp();
const { t } = useI18n();
const rewardsStore = useRewardsStore();
const {
	rewardsBySubCategory,
	rewardByCategories,
	isLoading,
	rewardsError,
	categoriesWithSubCategories,
	isCashbackAvailable,
} = storeToRefs(rewardsStore);
const userStore = useUserStore();
const searchValue = ref('');
const selectedRewardOptionId = ref(0);

const handleCloseModal = () => {
	emit('close-modal');
};

onMounted(async () => {
	selectedRewardOptionId.value = 0;

	if (open) {
		await rewardsStore.fetchRewards();
	}
});

const filteredRewards = computed<Record<
	RewardCategories,
	RewardsItem[]
> | null>(() => {
	if (rewardByCategories.value && searchValue.value) {
		const filteredItems = {} as Record<string, RewardsItem[]>;
		for (const [key, value] of Object.entries(rewardByCategories.value)) {
			filteredItems[key] = value.filter((item) =>
				item.name.toLowerCase().includes(searchValue.value.toLowerCase())
			);
		}
		return filteredItems;
	} else {
		return rewardsBySubCategory.value;
	}
});

const isEmptyFilterRewards = computed(() =>
	filteredRewards.value
		? !Object.values(filteredRewards.value).filter((item) => item.length).length
		: false
);

const emptySearchResult = computed(() => {
	return searchValue.value.length
		? t(NO_REWARDS_WITH_NAME, { value: searchValue.value })
		: t(NO_REWARDS_MESSAGE);
});

const postSelectedReward = async () => {
	if (selectedRewardOptionId.value) {
		const responseStatus = await rewardsStore.selectReward(
			selectedRewardOptionId.value
		);

		if (responseStatus === 422) {
			notify({
				body: rewardsError.value,
			});
			return;
		}
		await userStore.fetchUserData();
		selectedRewardOptionId.value = 0;
		handleCloseModal();
	}
};

const setSelectedOption = (event: number) => {
	selectedRewardOptionId.value = event;
};

const footerPaddingStyle = computed(() => {
	return platform.value === Platforms.IOS
		? '1.125rem 1.125rem calc(env(safe-area-inset-bottom))'
		: '1.125rem 1.125rem 1.125rem';
});
</script>

<template>
	<div class="rewards-modal-container">
		<div class="modal-header">
			<PrimeText size="base" weight="600">{{ $t(SELECT_REWARDS) }}</PrimeText>
			<button class="close-button" @click="handleCloseModal">
				<PiClose size="1.25rem" />
			</button>
		</div>

		<PrimeInput
			v-model="searchValue"
			rounded="rounded"
			:start-icon="PiSearch"
			:placeholder="$t(SEARCH_REWARDS)"
			class="search-reward-wrapper"
		/>

		<RewardsCashback v-if="isCashbackAvailable" />

		<div
			v-if="rewardsStore.initLoading"
			class="rewards-list"
			:class="{ 'has-cashback': isCashbackAvailable }"
		>
			<PrimeSkeleton
				v-for="i in 7"
				:key="i"
				class="reward-skeleton"
				width="auto"
				height="3.6rem"
			/>
		</div>

		<div
			v-else-if="!isEmptyFilterRewards"
			class="rewards-list"
			:class="{ 'has-cashback': isCashbackAvailable }"
		>
			<template
				v-for="(categoryItems, categoryName) in filteredRewards"
				:key="categoryName"
			>
				<PromotedRewards
					v-if="
						open && categoryItems.length && categoryName === Categories.PROMOTED
					"
					:items="categoryItems"
					:selected-option="selectedRewardOptionId"
					@select-option="setSelectedOption"
				/>
				<RewardsCategory
					v-if="categoryItems.length && categoryName !== Categories.PROMOTED"
					v-model:selected-option="selectedRewardOptionId"
					:category-items="categoryItems"
					:category="categoryName"
					:is-filtered="!!searchValue.length"
					:sub-categories="
						categoriesWithSubCategories[categoryName] && !searchValue
					"
				/>
			</template>
		</div>

		<div v-else class="fallback-wrapper">
			<PrimeText tag="p" weight="600" size="2xl" class="fallback-title">
				{{ $t(NO_RESULTS) }}
			</PrimeText>
			<PrimeText tag="p" color="grey-600" class="fallback-message">
				{{ isEmptyFilterRewards ? emptySearchResult : $t(NO_REWARDS_MESSAGE) }}
			</PrimeText>
		</div>
		<div v-if="!isEmptyFilterRewards" class="modal-footer">
			<PrimeButton
				type="gradient"
				full-width
				:label="$t(SELECT)"
				class="select-button"
				rounded="rounded"
				:disabled="!selectedRewardOptionId"
				@click="postSelectedReward"
			/>
		</div>

		<PrimeLoader v-if="isLoading" />
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.25rem;

	.close-button {
		cursor: pointer;
		background-color: transparent;
		border: none;
		&:hover {
			opacity: 0.7;
		}
	}
}

:deep(.search-reward-wrapper) {
	margin-bottom: 0.675rem;
}

.reward-skeleton {
	margin: 0.75rem 1.5rem;
}

.rewards-list {
	padding: 0.75rem 0.75rem 6.875rem;
	height: calc(100% - 6rem);
	overflow-y: scroll;
	margin: 0 -1.125rem;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;

	&::-webkit-scrollbar {
		width: 0;
	}

	&.has-cashback {
		height: calc(100% - 14.3rem);
	}

	@include breakpoint(mobile) {
		padding: 0.75rem 1.5rem 5rem;
		margin: 0 -1.5rem;
		height: calc(100% - 7rem);

		&.has-cashback {
			height: calc(100% - 12.3rem);
		}
	}
}

.modal-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-radius: 0 0 0.75rem 0.75rem;
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(1.5px);
	-webkit-backdrop-filter: blur(1.5px);
	padding: v-bind(footerPaddingStyle);
	background: white;
	border-top: 1px solid #eae7e7;

	@include breakpoint(mobile) {
		padding: 1.125rem 1.375rem;
	}

	.select-button {
		padding: 0.875rem;
	}

	:deep(.p-divider) {
		margin-left: -1rem;
		margin-right: -1rem;
		width: auto;

		@include breakpoint(mobile) {
			margin-left: -1.375rem;
			margin-right: -1.375rem;
		}
	}
}

.fallback-wrapper {
	height: calc(100% - 9.375rem);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& .fallback-title {
		margin-bottom: 0.125rem;
	}
}
</style>

<style lang="scss">
@import '@/styles/mixins.scss';

.rewards-modal-container {
	height: calc(90vh - 2.75rem);
	height: calc(90svh - 2.75rem);

	@include breakpoint(mobile) {
		height: 80vh;
		height: 80svh;
	}

	& .modal-content {
		height: 100%;
	}
}

.rewards-list {
	& .reward-category:last-of-type {
		& .category-wrapper {
			margin-bottom: 1.125rem;
		}
		& .category-divider {
			display: none;
		}
	}
}
</style>
