<script setup lang="ts">
import {
	OKAY,
	REWARD_SENT,
	REWARD_RECEIVE_TIME,
	REWARD_IS_ON_WAY,
} from '@/locales/constants';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContentV2.vue';
import congratulations from '@/assets/icons/congratulations.svg';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(REWARD_IS_ON_WAY) }}</template>
		<template #icon>
			<img :src="congratulations" alt="congratulations" />
		</template>
		<template #title>{{ $t(REWARD_SENT) }}</template>
		<template #message>{{ $t(REWARD_RECEIVE_TIME) }}</template>
		<template #actions>
			<PrimeButton
				type="fill"
				rounded="rounded"
				full-width
				:label="$t(OKAY)"
				@click="handleCloseModal"
			/>
		</template>
	</ModalCommonContent>
</template>
