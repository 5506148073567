<script setup lang="ts">
import { PROCEED } from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContentV2.vue';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { ref, computed } from 'vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRewardsStore } from '@/stores/rewards';
import confirmEmail from '@/assets/icons/confirm-email.svg';
import confirmFullname from '@/assets/icons/confirm-fullname.svg';

type ModalOptions = {
	userData: string;
	dataType: string;
	header: string;
	message: string;
	cancelLabel: string;
	confirmParams: Record<string, string>;
};

const { options } = defineProps<{
	options: ModalOptions;
}>();

const emits = defineEmits<{
	'close-modal': [];
}>();

const loading = ref(false);
const icon = computed(() => {
	if (options.dataType === 'full_name') {
		return confirmFullname;
	} else if (options.dataType === 'paypal_email') {
		return confirmEmail;
	}
	return false;
});

const rewardsStore = useRewardsStore();

const handleProceed = async () => {
	try {
		loading.value = true;
		await rewardsStore.claim(options.confirmParams);
		emits('close-modal');
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<div class="confirm-paypal-email-modal">
		<ModalCommonContent>
			<template #header>{{ $t(options.header) }}</template>
			<template #icon>
				<img v-if="icon" :src="icon" />
			</template>
			<template #box>
				<PrimeText tag="p" weight="500" size="sm" color="grey-700">
					{{ $t(options.dataType) }}
				</PrimeText>

				<PrimeText tag="p" weight="600" size="sm">
					{{ options.userData }}
				</PrimeText>
			</template>
			<template #message>
				{{ $t(options.message) }}
			</template>

			<template #actions>
				<PrimeButton
					type="outline"
					variant="grey"
					rounded="rounded"
					full-width
					:label="$t(options.cancelLabel)"
					@click="$emit('close-modal')"
				/>
				<PrimeButton
					type="fill"
					rounded="rounded"
					full-width
					:label="$t(PROCEED)"
					@click="handleProceed"
				/>
			</template>
		</ModalCommonContent>
		<PrimeLoader v-if="loading" />
	</div>
</template>
