export const DELETE_ACCOUNT_MODAL = 'delete_account_modal';
export const USER_LEVEL_MODAL = 'user_level_modal';
export const USER_STREAK_MODAL = 'user_streak_modal';
export const CHANGE_EMAIL_MODAL = 'change_email_modal';
export const CHANGE_PASSWORD_MODAL = 'change_password_modal';
export const CHANGE_USERNAME_MODAL = 'change_username_modal';
export const CHANGE_TIMEZONE_MODAL = 'change_timezone_modal';
export const WELCOME_BONUS_MODAL = 'welcome_bonus_modal';
export const PRESET_WELCOME_BONUS_MODAL = 'preset_welcome_bonus_modal';
export const REWARDS_MODAL = 'rewards_modal';
export const REWARD_SENT_MODAL = 'reward_sent_modal';
export const LEADERBOARD_MODAL = 'leaderboard_modal';
export const PRIZE_DRAW_MODAL = 'prize_draw_modal';
export const NEW_PASSWORD_SENT = 'new_password_sent';
export const ACHIEVEMENT_UNLOCKED_MODAL = 'achievement_unlocked_modal';
export const CHANGE_LANGUAGE_MODAL = 'change_language_modal';
export const ONE_CLICK_SURVEY_MODAL = 'one_click_survey_modal';
export const SELECT_COUNTRY_LANGUAGE_MODAL = 'select_country_language_modal';
export const SET_USER_PASSWORD_MODAL = 'set_user_password_modal';
export const NEW_LEVELS_MODAL = 'new_levels_modal';
export const CONFIRM_CLAIM_DATA_MODAL = 'confirm_claim_data_modal';
export const BONUS_CODE_MODAL = 'bonus_code_modal';
export const APP_REVIEW_MODAL = 'app_review_modal';
export const CONFIRM_CLAIM_MODAL = 'confirm_claim_modal';
export const GOOGLE_TRANSLATE_MODAL = 'google_translate_modal';
export const BONUS_DAY_MODAL = 'bonus_day_modal';
export const PRIZE_BONUS_MODAL = 'prize_bonus_modal';
export const ASK_TRACKING_PERMISSIONS_MODAL = 'ask_tracking_permissions_modal';
export const OFFERWALLS_ONETIME_NOTIFICATION_MODAL =
	'offerwalls_onetime_notification_modal';
export const OFFERWALLS_REWARD_NOTIFICATION_MODAL =
	'offerwalls_reward_notification_modal';
export const DYNAMIC_ANNOUNCEMENT_MODAL = 'dynamic_announcement_modal';
export const COPY_REFERRAL_LINK_MODAL = 'copy_referral_link_modal';
export const UPDATE_AVAILABLE_MODAL = 'update_available_modal';
export const OFFERWALLS_WELL_DONE_MODAL = 'offerwalls_well_done_modal';
export const OFFERWALLS_PENDING_MODAL = 'offerwalls_pending_modal';
export const CASHBACK_ANNOUNCEMENT_MODAL = 'cashback_announcement_modal';
export const CASHBACK_RECEIVED_NOTIFICATION_MODAL =
	'cashback_received_notification_modal';
export const ANNOUNCEMENT_MODAL_VERSION = 'announcement_modal_version';
