import { nextTick, ref } from 'vue';
import { createI18n } from 'vue-i18n';
import defaultMessages from '@/locales/en.json';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { supportedLocales as localizationsList } from '@/constants/localizations';
import { useAuthStore } from '@/stores/auth';
import { getLanguage } from '@/utils/helpers';

export const supportedLocales: Record<string, { name: string }> = {
	en: { name: 'English' },
	de: { name: 'German' },
	es: { name: 'Spanish' },
	fr: { name: 'French' },
	it: { name: 'Italian' },
	nl: { name: 'Dutch' },
	pt: { name: 'Portuguese' },
	pl: { name: 'Polish' },
	ar: { name: 'Arabic' },
	cs: { name: 'Czech' },
	da: { name: 'Danish' },
	fi: { name: 'Finnish' },
	id: { name: 'Indonesian' },
	ja: { name: 'Japanese' },
	ko: { name: 'Korean' },
	ms: { name: 'Malay' },
	no: { name: 'Norwegian' },
	ro: { name: 'Romanian' },
	sv: { name: 'Swedish' },
	th: { name: 'Thai' },
	tr: { name: 'Turkish' },
	vi: { name: 'Vietnamese' },
	zh: { name: 'Chinese' },
	fil: { name: 'Filipino' },
};

export const defaultLanguage = 'en';
export const defaultCountry = 'us';
export const defaultLocalization = 'en-us';

const _i18n = ref();

function setLocale(newLocale: string): void {
	_i18n.value.global.locale = newLocale;
	setDocumentAttributesFor(newLocale);
}

export function getCurrentLocale(): string {
	return _i18n.value.global.locale;
}

async function loadMessagesFor(locale: string): Promise<void> {
	const messages = await import(
		/* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
	);
	_i18n.value.global.setLocaleMessage(locale, messages.default);
	return nextTick();
}

function setDocumentAttributesFor(locale: string): void {
	const htmlElement = document.querySelector('html');
	htmlElement?.setAttribute('lang', locale);
}

export async function handleUserLocale(
	userLocalization: string | null
): Promise<void> {
	let language = defaultLanguage;
	let localization = defaultLocalization;
	const { setLocalizationCookie } = useAppCookies();
	const { setLocalization } = useAuthStore();

	if (
		userLocalization &&
		localizationsList.includes(userLocalization?.toLowerCase())
	) {
		language = getLanguage(userLocalization);
		localization = userLocalization;
	}

	setLocalizationCookie(localization);
	setLocalization(localization);

	await loadMessagesFor(language);
	setLocale(language);
}

function setup(options = { locale: defaultLanguage }) {
	_i18n.value = createI18n({
		legacy: false,
		locale: options.locale,
		fallbackLocale: defaultLanguage,
		messages: { [defaultLanguage]: defaultMessages },
	});
	setLocale(options.locale);
}

export default {
	get vueI18n() {
		return _i18n.value;
	},
	setup,
};
